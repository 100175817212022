<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="introduction">
            <video src="https://whg-bucket.oss-cn-shenzhen.aliyuncs.com/default/20221031/testmp4.mp4" controls ref="video"></video>
            <div class="right">
                <div class="title">校园文化艺术节</div>
                <!--                <div class="teacher">授课老师：{{ data.teacherName }}</div>-->
                <div class="time">
                    <img src="@/assets/images/Frame-37.png" alt="" />
                    2022-09-12 12:00
                </div>
                <div class="reading">
                    <img src="@/assets/images/Frame-38.png" alt="" />
                    2452
                    <img src="@/assets/images/Frame-29.png" alt="" />
                    2452
                </div>
                <div class="line"></div>
                <div class="list">
                    <div class="item" v-for="(item, index) in 10" :key="index">
                        <div class="userInfo">
                            <img src="https://whg-bucket.oss-cn-shenzhen.aliyuncs.com/2022/11/08/7b4aba93-123f-4164-8b2e-12817e916fcd.jpg" alt="" />
                            <p>张三</p>
                            <span>2021-12:32</span>
                        </div>
                        <div class="content">农工党抗击新冠肺炎疫情表彰大会召开农工党抗击新冠肺炎疫情表彰大会召开农工党抗击新冠肺炎疫情表彰大会召开农工党抗击新冠肺炎疫情表彰大会召开 陈竺讲话 何维主持</div>
                    </div>
                </div>
                <div class="button">
                    <div class="left">
                        <el-input type="text" v-model="content" placeholder="请输入评论内容">
                            <img slot="prefix" class="avatar" :src="avatar" alt="" />
                            <img slot="suffix" src="@/assets/images/Frame-49.png" alt="" />
                        </el-input>
                    </div>
                    <img src="@/assets/images/Frame-29.png" alt="" />
                </div>
            </div>
        </div>
        <div class="w-title">活动单位</div>
        <div class="w-content">主板单位：某某单位；承办单位：某某单位</div>
        <div class="w-title">直播简介</div>
        <div class="w-content">简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介</div>
        <div class="w-title">猜你喜欢</div>
        <div class="list">
            <div class="item" @click="$router.push({ name: 'enjoyActivitiesDetail', query: { id: index } })" v-for="(item, index) in 4" :key="index">
                <img class="active" :src="'https://unsplash.it/1600/900?' + index" alt="" />
                <div class="info">
                    <div class="title">校园文化艺术节</div>
                    <div class="desc" style="margin-top: 40px">时间：2022-09-12</div>
                    <div class="desc">来源：某某来源</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            nav: [
			    {
			        title: '首页',
			        to: '/',
			    },
			    {
			        title: '推荐资源',
			    },
			    {
			        title: '享活动',
			        to: { path: '/recommended/enjoyActivities' },
			    },
			    {
			        title: '详情',
			    },
            ],
            playIndex: 0,
            id: 0,
            data: {},
            avatar: '',
            content: '',
        };
    },
    watch: {
        playIndex() {
            this.$refs.video.play();
        },
    },
    created() {
        this.id = this.$route.query.id;
        this.avatar = JSON.parse(localStorage.getItem('userInfo')).avatar;
        this.getDetail();
    },
    methods: {
        getDetail() {
            this.$hao.getMooclearnDetail(this.id).then((res) => {
                this.data = res.data;
            });
        },
        // 收藏
        collection() {
            if (this.data.colletionId === null) {
                this.$hao.addPraiseCollection({ relatedId: this.data.id, type: 9 }).then((res) => {
                    this.$message.success('收藏成功');
                    this.data.colletionId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.data.colletionId).then(() => {
                    this.$message.success('取消收藏成功');
                    this.data.colletionId = null;
                });
            }
        },
        // 点赞
        praise() {
            if (this.data.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: this.data.id, type: 10 }).then((res) => {
                    this.$message.success('点赞成功');
                    this.data.praiseId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.data.praiseId).then(() => {
                    this.$message.success('取消点赞成功');
                    this.data.praiseId = null;
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.introduction {
    display: flex;
    margin-top: 24px;
    video {
        width: 900px;
        height: 506.25px;
    }
    .right {
        border: 1px solid #ebebeb;
        //margin-left: 20px;
        overflow: hidden;
        height: 506.25px;
        flex: 1;
        padding: 12px 16px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        .title {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
        }
        .teacher {
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
        }
        .time {
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
            display: flex;
            align-items: center;

            img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
        .reading {
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
            display: flex;
            align-items: center;
            img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
            img:last-child {
                margin-left: 8px;
            }
        }
        .line {
            width: 100%;
            height: 1px;
            background: #e5e5e5;
            margin-top: 20px;
        }
        .list {
            flex: 1;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            width: 100%;
            .item {
                margin-top: 12px;
                .userInfo {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        margin-right: 8px;
                    }
                    p {
                        font-size: 13px;
                        color: #333333;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 13px;
                        color: #999999;
                    }
                }
                .content {
                    font-size: 13px;
                    color: #333333;
                    padding-bottom: 12px;
                    border-bottom: solid #ebebeb 1px;
                    margin-top: 8px;
                    margin-left: 40px;
                }
            }
        }
        .button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            background: #eff1f7;
            box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.06);
            margin: -12px -16px;
            padding: 12px 16px;
            box-sizing: border-box;
            .left {
                display: flex;
                align-items: center;
                border: 0;
                flex: 1;
                overflow: hidden;
                input {
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    padding: 8px 10px;
                }
                ::v-deep .el-input__inner {
                    padding-left: 34px;
                    border-radius: 16px;
                    padding-right: 46px;
                    height: 32px;
                    font-size: 12px;
                    line-height: 17px;
                    color: rgba(0, 0, 0, 0.5);
                }
                img {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    margin-top: 4px;
                }
                ::v-deep .el-input__suffix-inner img {
                    margin-right: 13px;
                    margin-top: 7px;
                }
                .avatar {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
            img:not(:first-child) {
                width: 20px;
                height: 20px;
                margin-left: 16px;
            }
        }
    }
}
.w-title {
    margin-top: 24px;
    font-weight: 700;
    font-size: 24px;
    color: #333333;
}
.w-content {
    font-size: 16px;
    margin-top: 12px;
    color: #666666;
}

.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-bottom: 24px;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            position: relative;
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                color: #333333;
                width: 100%;
                .ellipsisLine(1);
            }

            .desc {
                font-size: 12px;
                line-height: 17px;
                color: #999999;
                width: 100%;
                margin-top: 12px;
                .ellipsisLine(2);
            }
        }
    }
}
</style>
